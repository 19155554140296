.news-container{
  .title{
    font-size: 28px;
    font-weight: 600;
    line-height: 114%;
    letter-spacing: 0px;
    margin-bottom: 40px;
  }
  .year {
    border-top:1px solid #000;
    padding:12px 0;
  }
  .new-content{
    ol, ul{

      padding-left: 1rem;


    }
    span {
      font-size: 16px !important;
    }
    li {
      padding: 6px 0;
    }
    font-size: 16px !important;
    line-height: 18px;
  }
  .versions{
    .news-item{
      padding-top: 22px;
      border-top:1px solid #000;
      display: flex;
      flex-flow: row;
    }
    .public-date {
      padding:2px 0;
      margin-right: 40px;
    }
  }
}