.dialog-container{
  position: absolute;
  top: 0;
  display: none;
  background:rgba(255,255,255, 0.1);
  width: 100%;


  height: 100vh;

  flex-flow: column;
  justify-content: center;
  .dialog{
    background: #000;
    max-width:300px;
    width:100%;
    text-align: center;
    padding: 30px 35px;
    border-radius: 8px;
    margin:0 auto;
  }
  .icon{

    img {
      width:46px;
    }
  }
  .msg {
    margin-top:30px;
    color:#fff;
  }
}