.navigation-container{
  display:flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

 // border:1px solid #000;
  .button{
    width: 20px;
  //  height:30px;
    img {
      width: auto;
    }
    a {
      cursor:pointer;
    }
  }
  .title{
    font-size:16px;
    font-weight: 600;
  }
  margin-bottom:48px;
  .top-right-buttons{
    display: flex;
    flex-flow:row nowrap;;
    align-items: center;
    .open-msg-button{
      margin-right: 20px;
    }
    .button-link{
      margin-right: 20px;
    }
    .button-link.current {
      a {
        background: #000;
        &:visited {
          color:white;
        }
      }
    }
    .button-link a{

      font-size:10px;
      border-radius: 10px;
      display: inline-block;
      cursor: pointer;
      color:#000;
      border:1px solid #000;
      padding: 0px 7px;
      font-weight:  bold;
      text-decoration: none;
      &:visited {
        color:#000;
        text-decoration: none;

      }
    }
  }

}
.events, .brand, .product{
  .second-navigation{
    display: block;
    text-align: left;
    .row-second{
      margin-bottom:28px;
      &:last-child{
        margin-bottom: 0;
      }
      display: flex;
      flex-flow: row nowrap;
      .item-wrap{
        //flex-grow:1;
        width:33.3%;
       // border:1px solid #000;
        text-align: left;
      }
      .item {
        padding: 0 0px;
        text-align: left;

        display: inline-block;
        /*a{
          display: flex;
          flex-flow: column nowrap;

          &:after {
            display: inline-block;
            margin:0;
            margin-top: 3px;
          }
        }*/
      }
    }
  }
  .third-navigation{
    border-top:1px solid #000;
    margin-top: 30px;
    padding-top: 20px;
    justify-content: left;
    text-align: center;
    .item-wrap{
      width:33.3%;
      text-align: left;

    }
    .third-item{
     // border:1px solid #f00;
      display: inline-block;
      padding: 0;
      text-align: left;
      margin-right: 66px;

      &.current {
        /*a{
          display: flex;
          flex-flow: column nowrap;

            &:after {

              display: inline-block;
              margin:0;
              margin-top: 3px;
            }


        }*/
      }

    }
    @media #{$phone-only} {
      .third-item{
        margin-right: 33px;
      }

    }
  }
}

.second-navigation{

//  border:1px solid #f00;
  display: flex;
  text-align: center;
  flex-flow: row nowrap;
  //align-items: center;
  justify-content: center;
  .item {
    padding: 0 20px;

    //margin-right: 41px;
    a {
      cursor:pointer;
      letter-spacing: 2px;
    }
    &.current {
      a {
        font-weight: 600;
       /* &:after {
          content: "";
          display: block;
          width: 16px;
          margin: 0 auto;
          margin-top: 3px;
          border-bottom: 2px solid #000;
        }*/
      }
    }
  }

}

.third-navigation{
  display: none;

  text-align: center;
  flex-flow: row nowrap;
  //align-items: center;
  justify-content: center;
  margin-top: 35px;
 // border:1px solid #f00;
  &.active{
    display: flex;
  }
  .third-item{
    padding: 0 17px;
    a {
      cursor:pointer;
      font-size:12px;
      font-weight: normal;
    }
    &.current {
      a {
        font-weight: 500;
        /*&:after {
          content: "";
          display: block;
          width: 16px;
          margin: 0 auto;
          margin-top: 3px;
          border-bottom: 1px solid #000;
        }*/
      }
    }
  }
}
@media #{$phone-only} {

}


