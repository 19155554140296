.folder {
  padding-bottom: 40px;

  .title {
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    font-weight: 600;

    &:after {
      content: "";
      display: block;
      width: 16px;
      margin: 0 auto;
      margin-top: 6px;
      margin-bottom: 40px;
      border-bottom: 1px solid #000;
    }
  }

  .category-name {
    padding: 10px 0 30px 0;
    font-weight: bold;
    font-size: 12px;
  }

  .file {
    &.col-6 {

      .cover {
        margin-bottom: 10px;
      }
    }

    .cover {
      overflow: hidden;
      border-radius: 8px;
      font-size: 0;
      //margin-bottom: 19px;
    }

    // margin-bottom: 60px;

  }

  .button-container {

    //margin-bottom:30px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-size: 14px;

    align-items: start;
    .buttons{
      display: flex;
      flex-flow: row nowrap;
    }
    //   margin-top: 6px;
    .text {

      font-weight: normal;
      margin: 0;
      display: block;

      padding: 0;

      .filename {
        margin-top:12px;
        display: inline-block;
        line-height: 18px;
        padding-bottom:12px;
        word-break:break-all;
      }

      .description {
        display: block;
        word-wrap: break-word;
        white-space: pre-wrap;
        font-size:10px;
        line-height: 14px;
        padding-bottom:12px;
        a {
          color: #000;
        }
        a:visited {
          color: #000;
        }
      }
    }

    .btn {
      cursor: pointer;
      display: inline-block;
      font-size: 0;
    //border:1px solid #f00;
      &.btn-download {
        display: inline-block;

        overflow: hidden;
        font-size: 0;
        width: 40px;
        height: 40px;
        background: url('/dist/images/download_btn.svg') right center;
        background-repeat: no-repeat;
      }


      &.btn-share {
        display: inline-block;
        overflow: hidden;
        font-size: 0;
        width: 40px;
        //border:1px solid #f00;
        height: 40px;
        background: url('/dist/images/btn-share.svg') center center;
        background-repeat: no-repeat;
      }
    }
  }

  .downloads_container {

    width: 100%;
    padding-bottom: 30px;

    table {
      width: 100%;
      border-collapse: collapse;
    }
    tr {
      &:last-child td {
       // border-bottom:0.5px solid #000;
        &:after{
          content: " ";
          position: absolute;
          bottom: 0;
          right: 0;
          height: 1px;
          border-bottom: 1px solid #000;
          color: #d9d9d9;
          -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
          -webkit-transform: scaleY(.5);
          transform: scaleY(.5);
          left: 0;
          z-index: 2;
        }
      }
    }
    td{
      border-collapse: collapse;
      //border:1px solid #f00;
      font-size:10px;
     // padding:10px 0;
     // border-top:0.5px solid #000;
      position: relative;
      &.alignCenter{
        text-align: center;

      }
      &:before{
        content: " ";
        position: absolute;
        top: 0;
        right: 0;
        height: 1px;
        border-bottom: 1px solid #000;
        color: #d9d9d9;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: scaleY(.5);
        transform: scaleY(.5);
        left: 0;
        z-index: 2;
      }
    }
    .btn {
      cursor: pointer;
      display: inline-block;
      font-size: 0;

      &.btn-download {
        display: inline-block;

        overflow: hidden;
        font-size: 0;
        width: 40px;
        height: 40px;
        background: url('/dist/images/download_btn.svg') center center;
        background-repeat: no-repeat;
      }
      &.btn-link {
        display: inline-block;

        overflow: hidden;
        font-size: 0;
        width: 40px;
        height: 40px;
        background: url('/dist/images/btn-link.svg') center center;
        background-repeat: no-repeat;
      }
    }
  }
}