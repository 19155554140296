//response.scss 引用当前的变量
$phone-ls-min: 480px !default;
$tablet-small-min: 600px !default;
$tablet-min: 768px !default; //ipad
$tablet-max: 1024px !default; //ipad pro
$desktop-min: 992px !default;
$desktop-wide-min: 1200px !default;
$desktop-extra-wide-min: 1440px !default;
$desktop-super-wide-min: 1672px !default;

$breakpoints: (
        galaxy5s: 360px,
        iphone6: 375px,
        iphone6plus: 414px,
        phone-ls: $phone-ls-min,
        tablet-small: $tablet-small-min,
        tablet: $tablet-min,
        desktop: $desktop-min,
        desktop-wide: $desktop-wide-min,
        desktop-extra-wide: $desktop-extra-wide-min,
        desktop-super-wide: $desktop-super-wide-min,
);

$phone-max: ($phone-ls-min - 1) !default;
$phone-ls-max: ($tablet-small-min - 1) !default;
$tablet-small-max: ($tablet-min - 1) !default;
$tablet-max: ($desktop-min - 1) !default;
$desktop-max: ($desktop-wide-min - 1) !default;
$desktop-wide-max: ($desktop-extra-wide-min - 1) !default;
$desktop-extra-wide-max:($desktop-super-wide-min - 1) !default;;
$phone-ls: "(min-width:#{$phone-ls-min})";
$tablet-small: "(min-width:#{$tablet-small-min})";
$tablet: "(min-width:#{$tablet-min})";
$desktop: "(min-width:#{$desktop-min})";
$desktop-wide: "(min-width:#{$desktop-wide-min})";
$desktop-extra-wide: "(min-width:#{$desktop-extra-wide-min})";
$desktop-super-wide: "(min-width:#{$desktop-super-wide-min})";

$phone-only: "(max-width:#{$phone-max})";
$phone-phone-ls-only: "(max-width:#{$phone-ls-max})";
$phone-tablet-small-only: "(max-width:#{$tablet-small-max})";
$phone-tablet-only: "(max-width:#{$tablet-max})";
$phone-tablet-max-only: "(max-width:#{$tablet-max})"; //2021-08-07

$phone-ls-only: "(min-width:#{$phone-ls-min}) and (max-width:#{$phone-ls-max})";
$phone-ls-tablet-small-only: "(min-width:#{$phone-ls-min}) and (max-width:#{$tablet-small-max})";

$tablet-small-only: "(min-width:#{$tablet-small-min}) and (max-width:#{$tablet-small-max})";
$tablet-small-tablet-only: "(min-width:#{$tablet-small-min}) and (max-width:#{$tablet-max})";

$tablet-only: "(min-width:#{$tablet-min}) and (max-width:#{$tablet-max})";
$tablet-desktop-only: "(min-width:#{$tablet-min}) and (max-width:#{$desktop-max})";
$tablet-desktop-extra-wide-only: "(min-width:#{$tablet-min}) and (max-width:#{$desktop-extra-wide-max})";

$desktop-only: "(min-width:#{$desktop-min}) and (max-width:#{$desktop-max})";
$desktop-small-only: "(min-width:#{$desktop-min}) and (max-width:#{$desktop-extra-wide-min})";

$galaxys5: "(min-width: 360px)";
$iphone6: "(min-width: 375px)";
$iphone6plus: "(min-width: 414px)";

$container-tablet-small: 590px;
$container-tablet: 740px;
$container-desktop: 952px;
$container-desktop-wide: 1160px;
$container-desktop-extra-wide: 1293px;
$container-desktop-super-wide: 1432px;

$portrait: "(orientation: portrait)";
// ABOUT TO BE DEPRECATED

$container-ts: $container-tablet-small;
$phablet: $phone-ls;
$phablet-min: $phone-ls-min;
$phablet-only: $phone-ls-only;
$phone-phablet-only: $phone-phone-ls-only;