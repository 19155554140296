html{
  padding:0;
  margin:0;
}
body{

  margin:0;
  padding: 0;
  font-family: 'Montserrat';
}

.main-container{
  margin: 0 auto;
 // border:1px solid #0f0;
  padding: 26px 20px 53px 20px;
  position: relative;
}
.menu-container{
  position: absolute;

  padding: 26px 20px 53px 20px;
  top: 0;
  left:0;
  display: none;
  background:#fff;
  z-index:999;

  width: 100%;
  /*border:1px solid #f00;*/
  //height: 100vh;
  .close-btn {
    a {
      color:#000;
      text-decoration: none;
    }
    position: relative;
    display:flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    p {
      margin:0;
      font-size:16px;
      font-weight: 600;
    }
  }
  .navi-items{
    //padding-top:60px;
    padding-top:10px;
    text-align: center;
    .item{
      font-size:28px;
     // line-height: 15px;
      text-align: center;
      letter-spacing: 2px;
      margin-bottom: 15px;
      a {
        color: #000;
        text-decoration: none;
      }

      &.current{
       // border:1px solid #f00;
        .title:after{

          content: "";
          display: block;
          width: 32px;
          margin: 0 auto;
          margin-top: 6px;
        //  margin-bottom: 40px;
          border-bottom: 2px solid #000;
        }
      }
    }
  }
}
@media (min-width:#{$tablet-small-min}) {
  .main-container {
    max-width: $tablet-min;
   // border:1px solid #f00;
  }
}


