.middle {
  .title{
    font-size:14px;
    margin-bottom:14px;
    line-height: 15px;

    font-weight: 600;
  }
  .panel{
    margin-top:40px;
    &.brand{
      img{
        border-radius: 8px;
      }

    }
  }
}
img{
  width: 100%;

}