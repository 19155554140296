.login-container{
  height: 100vh;
//  border:1px solid #f00;
  display: flex;
  flex-flow: column;
  justify-content: start;
  align-items: center;
  .login-logo{
    img {
      width: 79px;
    }
    margin-bottom: 63px;
    margin-top: 138px;
  }
  .form-field{

    input{
      width: 270px;
      padding:0;
      margin:0;
      border:0;
      font-size:14px;
      outline:none;
      text-align: center;
      background: none;
      height: 45px;
      line-height: 45px;
      border:1px solid #000;
      border-radius: 4px;
    }
    margin-bottom: 20px;
  }
  .form-buttons{
    margin-top: 40px;
    text-align: center;
    button{
      width:116px;
      margin:0 auto;
      background: #000;
      color:white;
      font-size: 18px;
      border: 0;
      line-height: 40px;
      border-radius: 4px;
    }
  }
  .form-field-error{
    text-align: center;
    color:#DB2323;
    font-size: 12px;
    margin-top: 10px;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  //自动填充时的颜色
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}