.panel-dialog{
  padding: 25px 10px;
  text-align: center;
 // border:1px solid #000;
  .feedback-icon{
    margin-bottom: 20px;

  }
  .feedback-highlight{
    font-size: 16px;
    font-weight: 600;
    line-height: 122%;
    text-align: center;
    text-transform: capitalize;
    letter-spacing: 0px;

  }
  .feedback-buttons{
    margin-top: 24px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    a {
      padding: 7px;
      width:96px;
      cursor: pointer;
      border:1px solid #000;
      display:block;
      font-size: 12px;
      color:#000;
      line-height: 122%;
      text-decoration: none;
      align-items: center;
      text-transform: capitalize;
      letter-spacing: 0px;
      &.btn-block{
        background:#000;
        color:white;
      }
    }
  }

}

.no-top{

    padding: 0px;
    .panel-header{
      padding: 16px 18px 18px 18px;
    }
    .panel-content{
      padding: 16px 18px 18px 18px;
    }

  .panel-header {
    border-radius: 8px 8px 0 0 ;
    display: flex;
    align-items: center;
    background:#F1F1F1;
    .icon{
      display: flex;
      align-items: center;
      line-height: 21px;
      font-weight: bold;
      color:rgba(61, 61, 61, 0.35);
      &.icon-message{

        &:before {
          content: " ";
          margin-right: 13px;
          display:inline-block;
          width: 21px;
          height: 19px;
         // border:1px solid #f00;
          background: url("/dist/images/icon-feedback.svg") no-repeat;
        }
      }
    }
  }
  .form-item {
      label{
        padding:6px 0;
      }
    .textfield-wrap{

      &.bottom-border{
        border-bottom:1px solid #BFBFBF;
      }
      input, textarea {
        width: 100%;
        border:0;
        outline: none;
        background-color: transparent;
        &::placeholder {
          color: #000;
          opacity: 1; /* Firefox */
        }

        &::-ms-input-placeholder { /* Edge 12 -18 */
          color: #000;
        }
      }
      input.textfield {
        line-height: 27px;

      }
    }
    &.mt6{
      margin-top:6px;
    }
    &.textarea-item{
      .textfield-wrap{
        background: #F1F1F1;
        border-radius: 8px;
        padding: 16px 13px;
      }
      textarea{
        min-height: 260px;
     //   border:1px solid #000;
      }
    }
  }
  .button-bar{
    margin-top:24px;
    display: flex;
    justify-content: end;
    a {
      padding: 7px;
      width:96px;
      cursor: pointer;
      border:1px solid #000;
      display:block;
      font-size: 12px;
      color:#000;
      line-height: 122%;
      text-decoration: none;
      align-items: center;
      text-transform: capitalize;
      letter-spacing: 0px;
      text-align: center;
      &.btn-submit{
        background:#000;
        color:white;
      }
      &.btn-disabled {
        cursor: default;
        background:#F1F1F1;
        color: #000;
        border: 1px solid #F1F1F1;
      }
    }
  }


}