.close {
  position: relative;
  //right: 25px;
 // top: 55px;
  width: 17px;
  height: 17px;
  right: 5px;
  cursor:pointer;
}
.close:hover {
  opacity: 1;
}
.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 17px;
  width: 1px;
  background-color: #000;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
.open {
  cursor: pointer;
}

