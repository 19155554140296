.content-tabs{
  .tab {
    display:none;
    padding-top:40px;
    &.active{
      display: block;
    }
  }
}
.third-tabs{
  .third-tab{
    display:none;
    &.active{
      display:block;
    }
  }
}