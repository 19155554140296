//https://www.jquerymodal.com/
.blocker {

  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  width: 100%; height: 100%;
  overflow: auto;
  z-index: 99;
  padding: 20px;
  box-sizing: border-box;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.75);
  text-align: center;
}
.blocker:before{
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.05em;
}
.blocker.behind {
  background-color: transparent;
}
.modal {
  display: none;
  vertical-align: middle;
  position: relative;
  z-index: 2;
  max-width: 100rem;
  box-sizing: border-box;
//  width: 90%;
  background: #fff;
  padding: 15px 30px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 0 10px #000;
  -moz-box-shadow: 0 0 10px #000;
  -o-box-shadow: 0 0 10px #000;
  -ms-box-shadow: 0 0 10px #000;
  box-shadow: 0 0 10px #000;
  text-align: left;
}

.modal a.close-modal {
  position: absolute;
  top: 17px;
  right: 16px;
  display: block;
  width: 16px;
  height: 16px;
  text-indent: -9999px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('/dist/images/btn-close.svg');

}

.modal-spinner {
  display: none;
  position: fixed;
  width: 100%;
  height:100%;
  top:0;
  left:0;
  //height: 20px;
  z-index: 9999;

}
.modal-spinner .spinnerContainer{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  padding: 12px 16px;
  border-radius: 5px;
  background:rgba(1, 1, 1, 0.75);
  line-height: 0;

  > div {
    // border-radius: 100px;
    background-color: #fff;
    height: 20px;
    width: 2px;
    margin: 0 1px;
    display: inline-block;
    line-height: 0;
    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;


  }
  .rect2 {

    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }
  .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }
  .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
}




@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.5) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.5);
    -webkit-transform: scaleY(0.5);
  }  20% {
       transform: scaleY(1.0);
       -webkit-transform: scaleY(1.0);
     }
}